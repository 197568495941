.container {
    width: 100vw;
    height: 100vh;
    background: url('../assets/axen-coin-fondo-dorado.gif');
    background-position: center;
    background-size: cover;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    padding-bottom: 5%;
    box-sizing: border-box;
}

.home-logo {
    margin-left: 0px;
    margin-right: auto;
    max-width: 900px;
    height: auto;
}

.logo-container {
    width: 100%;
    display: flex;
}

.update-now{
    color: white;
    text-decoration: none;
    transition: ease-in-out 0.3s;
    font-size: 18px;
}

.update-now:hover{
    color: #A2A0AA;
    transition: ease 0.3s;
}

@media (max-width: 990px) {
    .home-logo {
        width: 800px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 880px) {
    .home-logo {
        width: 750px;
        margin-left: auto;
        margin-right: auto;
    }

    .axencoin-image-rotary {
        margin-top: 40px;
        margin-bottom: 40px;
    }
}

@media (max-width: 780px) {
    .home-logo {
        width: 650px;
        margin-left: auto;
        margin-right: auto;
    }

    .axencoin-image-rotary {
        margin-top: 55px;
        margin-bottom: 55px;
    }
}

@media (max-width: 680px) {
    .home-logo {
        width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    .axencoin-image-rotary {
        margin-top: 70px;
        margin-bottom: 70px;
    }
}

@media (max-width: 400px) {
    .home-logo {
        width: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    .axencoin-image-rotary {
        margin-top: 85px;
        margin-bottom: 85px;
    }
}

.card-irregular-container {
    position: relative;
    width: 300px;
}

.card-irregular {
    box-sizing: border-box;
    color: white;
    font-weight: 700;
    font-size: 28px;

    width: 100%;
    height: 100%;
    position: absolute;
    top: -10px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
}

.card-irregular:hover {
    cursor: pointer;
    mask-image: linear-gradient(-75deg, rgba(0, 0, 0, .6) 30%, #000 50%, rgba(0, 0, 0, .6) 70%);
    -webkit-mask-image: linear-gradient(-75deg, rgba(0, 0, 0, .6) 30%, #000 50%, rgba(0, 0, 0, .6) 70%);
    -webkit-mask-size: 200%;
    mask-size: 200%;
    animation: shine 1s infinite;
}

@keyframes shine {
    from {
        -webkit-mask-position: 150%;
    }

    to {
        -webkit-mask-position: -50%;
    }
}

@-webkit-keyframes shine {
    from {
        -webkit-mask-position: 150%;
    }

    to {
        -webkit-mask-position: -50%;
    }
}