.polygon{
    background: rgb(210,210,210);
    background: linear-gradient(90deg, rgba(210,210,210,0.5) 0%, rgba(0,113,192,1) 100%);
	background-size: 150% 150%;
    clip-path: polygon(25% 5%, 75% 5%, 76% 60%, 95% 60%, 95% 80%, 75.9% 80%, 74.9% 25%, 25% 25%);
    margin-top: 100px;
    display: flex;
	animation: gradient 5s infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.bolita{
    background-color: transparent;
    border: 2.5px rgba(0,113,192,1) solid;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    left: 94.8%;
    bottom:12.5px;
    position: relative;
}

.data-container{
    padding: 30px;
    margin-bottom: -100px;
    position: relative;
}

.data-img{
    width: 100%;
}

.data-irregular{
    color: white;
    font-size: 20px;
    font-family: 'Montserrat';
    font-weight: 300;
    position: relative;
    top:-122.5px
}

.data-bold{
    font-weight: bold;
    margin-top: 5px;
}

.data-regular{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    color: white;
    font-size: 20px;
    font-family: 'Montserrat';
    font-weight: 300;
    background-color: rgba(190,190,190,0.27);
    margin: 19px;
    padding-top: 20px;
    padding-bottom: 30px;
}

.custom-divider{
    display: flex;
    background-color: rgba(190,190,190,0.7);
    width: 90%;
    height: 1.5px;
    margin-left: auto;
    margin-right: auto;
}

.little-info-container{
    color:white;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 300;
    margin-top: 15px;
}

.custom-divider-gradient{
    display: flex;
    background: rgb(33,33,33);
    background: linear-gradient(90deg, 
        rgba(33,33,33,0.2) 0%, 
        rgba(190,190,190,0.7) 15%, 
        rgba(190,190,190,0.7) 85%, 
        rgba(33,33,33,0.2) 100%);
    width: 75%;
    height: 1.5px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
}

.extra-margin-top{
    margin-top: 92.5px;
}

.next-row-text{
    font-size: 18px;
    padding-left: 25px;
    padding-right: 20px;
    text-align: center;
}

.remove-margin-top{
    margin-top: -104px;
}

.auxiliar-margin-top{
    margin-top: 90px;
}

@media(max-width:1200px){
    .data-container{
        padding: 10px;
        margin-bottom: -75px;
    }
    .data-irregular{
        font-size: 18px;
        position: relative;
        top:-110.5px
    }
    .data-regular{
        font-size: 18px;
        margin: 19px;
        padding-top: 12px;
        padding-bottom: 33.5px;
    }
    .next-row-text{
        font-size: 16px;
    }
    .remove-margin-top{
        margin-top: 16px;
    }
}

@media(max-width:899px){
    .data-container{
        padding: 50px;
        margin-bottom: -100px;
    }
    .data-irregular{
        font-size: 20px;
        position: relative;
        top:-150.5px
    }
    .data-regular{
        font-size: 20px;
        margin: 19px;
        padding-top: 20px;
        padding-bottom: 33.5px;
    }
    .next-row-text{
        font-size: 18px;
    }
    .auxiliar-margin-bottom{
        margin-bottom: 25px !important;
    }
    
}

@media(max-width:550px){
    .data-container{
        padding: 20px;
        margin-bottom: -50px;
    }
}

@media(max-width:470px){
    .data-container{
        padding: 10px;
        margin-bottom: -35px;
    }
    .data-irregular{
        font-size: 18px;
        position: relative;
        top:-125.5px
    }
    .data-regular{
        font-size: 18px;
    }
    .next-row-text{
        font-size: 16px;
    }
}

@media(max-width:399px){
    .data-container{
        padding: 0px;
    }
    .data-irregular{
        font-size: 16px;
        position: relative;
        top:-115px
    }
    .data-regular{
        font-size: 16px;
    }
    .next-row-text{
        font-size: 15px;
    }
}