/* POLIGONOS */
.polygon-standard-cuenta{
    clip-path: polygon(0% 95%, 35% 90%, 36% 50%, 100% 55%, 100% 55%, 36% 60%, 35% 100%, 0% 95%);

    background: rgb(210,210,210);
background: linear-gradient(90deg, rgba(210,210,210,0) 0%, rgba(210,210,210,1) 50%, rgba(255,255,255,0) 100%);
    background-size: 150% 150%;
    display: flex;
    width: 110%;
    animation: gradient 5s infinite;
    position: absolute;
    top: 50%;
}

.polygon-big-cuenta{
    clip-path: polygon(0% 95%, 45% 90%, 46% 50%, 100% 55%, 100% 55%, 46% 60%, 45% 100%, 0% 95%);

    background: rgb(210,210,210);
background: linear-gradient(90deg, rgba(210,210,210,0) 0%, rgba(210,210,210,1) 50%, rgba(255,255,255,0) 100%);
    background-size: 150% 150%;
    display: flex;
    width: 110%;
    animation: gradient 5s infinite;
    position: absolute;
    top: 50%;
}

@media (max-width: 1200px) {
    .polygon-big-cuenta{
        clip-path: polygon(0% 95%, 80% 90%, 81% 50%, 100% 55%, 100% 55%, 81% 60%, 80% 100%, 0% 95%);
    }

    .polygon-standard-cuenta{
        clip-path: polygon(0% 95%, 50% 90%, 51% 50%, 100% 55%, 100% 55%, 51% 60%, 50% 100%, 0% 95%);
    }
}