.little-info-container {
    color: white;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 300;
    margin-top: 15px;
}

.info-title {
    font-size: 20px;
    font-weight: 400;
    font-family: 'Montserrat';
}

.info-quantity {
    font-size: 25px;
    margin-bottom: 15px;
    font-weight: 600;
    font-family: 'Montserrat';
}

.info-divider-gradient {
    display: flex;
    background: rgb(33, 33, 33);
    background: linear-gradient(90deg,
            rgba(33, 33, 33, 0.2) 0%,
            rgba(190, 190, 190, 0.7) 15%,
            rgba(190, 190, 190, 0.7) 85%,
            rgba(33, 33, 33, 0.2) 100%);
    width: 65%;
    height: 1.5px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
}

.input-container {
    text-align: left;
    align-items: center;
}

@media (max-width: 900px) {
    .input-container {
        text-align: center;
        margin-top: 20px !important;
    }

    .bottom-container {
        margin-top: 0px !important;
    }

    .custom-input-container {
        margin-top: 10px !important;
    }
}

.input-real {
    display: flex;
    justify-content: center;
}

.input-text {
    font-family: 'Montserrat';
    font-weight: 400;
    color: white;
    font-size: 19px;
    margin-top: -15px !important;
}

.custom-input-container img {
    width: 230px;
}

.custom-input-container {
    position: relative;
    width: 230px;
    height: 60px;
    padding: 0px 0px !important;
}

.form-control-custom {
    position: absolute !important;
    top: -3px;
    left: -3px;
}

.bottom-container {
    margin-top: 20px !important;
}

.MuiSlider-thumb {
    color: rgba(0, 113, 192, 1);
}

.MuiSlider-rail {
    color: transparent;
}

.MuiSlider-track {
    color: rgba(0, 113, 192, 1);
}

.polygon-slider {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,113,192,0.5) 16%, rgba(0,113,192,1) 26%);

    clip-path: polygon(0 70%, 20% 70%, 26% 0, 26% 28%, 20.5% 98%, 0 98%);
    display: flex;
    width: 100%;
    margin-top: -24px;
    margin-left:-3px;
    animation: gradient 5s infinite;
    color: transparent;
}

/* background: rgb(0, 113, 192);
    clip-path: polygon(0 70%, 15% 70%, 25% 0, 28% 0, 15% 90%, 0 90%);
    display: flex;
    width: 100%;
    margin-top: -22.5px;
    animation: gradient 5s infinite;
    color: transparent;*/

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

/* POLIGONOS */
.polygon-standard{
    clip-path: polygon(0% 95%, 45% 90%, 46% 50%, 100% 55%, 100% 55%, 46% 60%, 45% 100%, 0% 95%);

    background: rgb(210,210,210);
background: linear-gradient(90deg, rgba(210,210,210,0) 0%, rgba(210,210,210,1) 50%, rgba(255,255,255,0) 100%);
    background-size: 150% 150%;
    display: flex;
    width: 110%;
    animation: gradient 5s infinite;
    position: absolute;
    top: 50%;
}

.polygon-big{
    clip-path: polygon(0% 95%, 65% 90%, 66% 50%, 100% 55%, 100% 55%, 66% 60%, 65% 100%, 0% 95%);

    background: rgb(210,210,210);
background: linear-gradient(90deg, rgba(210,210,210,0) 0%, rgba(210,210,210,1) 50%, rgba(255,255,255,0) 100%);
    background-size: 150% 150%;
    display: flex;
    width: 110%;
    animation: gradient 5s infinite;
    position: absolute;
    top: 50%;
}

@media (max-width: 1200px) {
    .polygon-big{
        clip-path: polygon(0% 95%, 80% 90%, 81% 50%, 100% 55%, 100% 55%, 81% 60%, 80% 100%, 0% 95%);
    }

    .polygon-standard{
        clip-path: polygon(0% 95%, 50% 90%, 51% 50%, 100% 55%, 100% 55%, 51% 60%, 50% 100%, 0% 95%);
    }
}

.font-style{
    font-family: 'Montserrat' !important;
    font-weight: 400 !important;
    color: white;
}