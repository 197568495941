.layout-container {
    width: 100vw;
    min-height: 100vh;
    background: url('../assets/background.gif');
    background-position: center;
    background-size: cover;
}

.polygon-layout {
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 21%, rgba(0, 113, 192, 0.5) 51%, rgba(0, 113, 192, 1) 68%);

    background-size: 150% 150%;
    clip-path: polygon(0% 5%, 75% 5%, 76% 60%, 95% 60%, 95% 80%, 75.9% 80%, 74.9% 25%, 0% 25%);
    /* clip-path: polygon(0% 15%, 25.33% 5%, 75% 5%, 76% 60%, 95% 60%, 95% 80%, 75.9% 80%, 74.9% 25%, 25.33% 25%, 0% 15%); */
    display: flex;
    animation: gradient-layout 5s infinite;
    margin-top: -5px;
}

@keyframes gradient-layout {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 50% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.bolita {
    background-color: transparent;
    border: 2.5px rgba(0, 113, 192, 1) solid;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    left: 94.8%;
    bottom: 12.5px;
    position: relative;
}


.logo-header {
    width: 100%;
}

.nav-links-container a {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 18px;
}

.button-dropdown {
    font-family: 'Montserrat' !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 32px !important;
    color: white !important;
}

.menu-item {
    font-family: 'Montserrat' !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    text-decoration: none !important;
    color: black !important;
}

.text-truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 90%;
    display: inline-block;
}

.MuiButton-text{
    color: white !important;
}

.MuiButton-text.active{
    color: #0071ce !important;
}
