.buy-container{
    width: 100%;
    margin-top: 30px;
    margin-left: -24px;
    color: white;
    font-family: Montserrat;
    padding: 20px;
    position: relative;
    z-index: 99;
    border: 4px solid rgba(0,113,192,0.5);
    border-radius: 20px;
    background-color: transparent;
    -webkit-box-shadow: inset 5px 5px 11px 1px rgba(0,0,0,0.83), 3px 3px 7px 1px rgba(0,0,0,0.83); 
    box-shadow: inset 5px 5px 11px 1px rgba(0,0,0,0.83) , 3px 3px 7px 1px rgba(0,0,0,0.83);
    background-color: rgba(20,20,100,0.2);
}

.buy-options{
    cursor: pointer;
    height: auto;
    display: flex;
    transition: transform 0.7s ease-in-out;
}

.buy-options:hover{
    transform: rotate(-360deg);
    transition: transform 0.7s ease-in-out;
}

.buy-slipText{
    margin-top: 30px;
    font-size: 12px;
    font-weight: 300;
    padding: 0 10px;
}

.receive-button{
    width: 200px;
    margin-top: 10px !important;
}

@media(max-width:500px){
    .buy-button{
        width: 100px;
    }
}

/* .buy-container::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 20px;
    border: 4px solid transparent;
    background: linear-gradient(90deg, 
        rgba(19,19,19,0.18930899996717432) 0%, 
        rgba(0,113,192,1) 100%) border-box;
    -webkit-mask:
       linear-gradient(#fff 0 0) padding-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
    z-index: 9;
} */

.hidden{
    display: none;
}